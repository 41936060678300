<template>
    <b-row class="pb-1 ">
        <b-col class="d-flex justify-content-end">
            <!-- <b-button
                :class="{ btnFixed: display }"
                :to="{ name: 'dashboard-camanchaca-cosecha-urgente' }"
                >Cosecha Urgente</b-button
            > -->
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

export default {
    components: {
        BRow,
        BCol,
        BButton
    },
    data() {
        return {
            display: false
        };
    },
    mounted() {
        this.display = true;
    }
};
</script>

<style lang="scss" scoped>
.btnFixed {
    background-color: #F26E28 !important ;
    border-color: #F26E28 !important ;
    position: fixed !important;
    margin-right: 55px !important;
    margin-top: -54px !important;
    // top: 92px !important;
    // right: 80px !important;
    z-index: 99 !important;
}
@media only screen and (max-width: 414px) {
    .btnFixed {
        width: 56%;
        margin-right: 0px !important;
        margin-top: -89px !important;
        
    }
}
</style>
